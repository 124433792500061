import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { LinkCoverDocument } from "../components/elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlackSection } from "../components/structure"

const PDFLinks = [
  {
    id: 1,
    name: "Business SIP-CIS.pdf",
    page: "/BusinessSip",
  },
  {
    id: 2,
    name: "Hosted Extension-CIS.pdf",
    page: "/HostedExtension",
  },
  {
    id: 3,
    name: "Microsoft Teams-CIS.pdf",
    page: "/MicrosoftTeams",
  },
  {
    id: 4,
    name: "NBN-CIS.pdf",
    page: "/NbnCis",
  },
]
export default function CriticalDocuments() {
  return (
    <Layout>
      <SEO title="Critical Information Summary" />
      <BlackSection padDoc title="Critical Information Summary" />
      <LinkCoverDocument>
        <Note>
          Important information: If you find difficulty accesing in smaller
          device screen than please open in Computer or Bigger screen devices.
        </Note>
        <Cover>
          {PDFLinks.map((items, id) => {
            return (
              <PdfNavLink to={items.page} target="_blank" alt={items.page}>
                {items.id} {items.name}
              </PdfNavLink>
            )
          })}
        </Cover>
      </LinkCoverDocument>
    </Layout>
  )
}

const PdfNavLink = styled(Link)`
  font-size: 2rem;
  color: ${props => props.theme.color.black};
  font-weight: 600;
  letter-spacing: 0.05em;
`

const Note = styled.p`
  color: brown;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 150%;
`

const Cover = styled.div`
  padding: 3rem 1rem;
  display: inline-flex;
  flex-direction: column;
`
